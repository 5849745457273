import { Order, OrderDetails, OrderProductDetails } from '@/models/Order';
import swal from 'sweetalert2';
import { generalStore } from '@/store';
import Q from 'q';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { SaveOrderObject, AddProductInExistOrderRequest } from '@/models/Interfaces';
import { api } from '@/services/Api';

export default function() {
    const { t } = useI18n();

    async function saveStoreOrder(order: OrderDetails): Promise<OrderDetails | undefined> {
        swal.showLoading();
        generalStore.commit('setOrder', order);
        await Q.delay(400);
        swal.close();
        return order;
    }

    async function AddProductInOrder(orderProduct: OrderProductDetails): Promise<void> {
        swal.showLoading();
        generalStore.commit('addProductInOrder', orderProduct);
        await Q.delay(400);
        swal.close();
    }
    async function AddProductInExistOrder(req: AddProductInExistOrderRequest): Promise<boolean> {
        swal.showLoading();
        const apiPromise = api.AddProductInExistOrder(req);
        await Q.delay(400);
        const apiResult = await apiPromise;
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.errorMessage
            });
            return false;
        }
        return true;
    }

    function removemillis(time: string) {
        if (!time) {
            return '';
        }
        const arr = time.split(':');
        const result = arr[0] + ':' + arr[1];
        return result;
    }

    function getEndTime(startTime: string, duration: string) {
        if (!startTime || !duration) {
            return '';
        }
        const startTimeArr = startTime.split(':');
        const durationArr = duration.split(':');
        const date = new Date();
        date.setHours(Number(startTimeArr[0]));
        date.setMinutes(Number(startTimeArr[1]));
        date.setHours(date.getHours() + Number(durationArr[0]));
        date.setMinutes(date.getMinutes() + Number(durationArr[1]));
        return '-' + date.getHours() + ':' + date.getMinutes();
    }

    function getMinutes(time: string) {
        const arr = time.split(':');
        return (Number(arr[0]) * 60 + Number(arr[1])).toString();
    }

    async function canLeave(to: any) {
        const orderSteps = [
            'neworder',
            'addorderproduct',
            'addorderproductslot',
            'addorderproductcustomertype',
            'order',
            'modal-order-edit-remarks'
        ];
        if (orderSteps.includes(to.name)) {
            return true;
        }
        const alert = await swal.fire({
            title: t('alert.areYouSure'),
            text: t('alert.ifYouLeaveThePage'),
            icon: 'warning',
            customClass: {
                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('alert.yesContinue'),
            cancelButtonText: t('button.cancel')
        });
        if (alert.isConfirmed) {
            generalStore.commit('clearOrder');
        }
        return alert.isConfirmed;
    }

    function addZero(number: number) {
        const str = number.toString();
        if (str.length == 1) {
            return '0' + str;
        }
        return str;
    }

    function convertDate(data: Date) {
        const day = data.getDate();
        const month = data.getMonth() + 1;
        const year = data.getFullYear();
        return year + '-' + addZero(month) + '-' + addZero(day);
    }

    function dateToddmmyyyy(data: Date) {
        const day = data.getDate();
        const month = data.getMonth() + 1;
        const year = data.getFullYear();
        return addZero(day) + '/' + addZero(month) + '/' + year;
    }

    function dateToJSON(date: Date | string) {
        return moment(date, moment.ISO_8601).format('DD/MM/YYYY');
    }

    async function apiSaveOrder(order: OrderDetails, onErrorMessages = (message: string) => true) {
        const date: SaveOrderObject = {
            priceTypeId: order.priceTypeId,
            leadCustomer: {
                email: order.leadCustomer.email,
                firstName: order.leadCustomer.firstName,
                lastName: order.leadCustomer.lastName,
                fullName: order.leadCustomer.lastName + ' ' + order.leadCustomer.firstName,
                groupName: order.leadCustomer.groupName,
                mobile: order.leadCustomer.mobile.replace(/\D+/g, ''),
                pid: order.leadCustomer.pid,
                pnr: order.leadCustomer.pnr,
                subscribe: order.leadCustomer.subscribe
            },
            businessClientId: order.businessClientId,
            discountAmount: Number(order.discountAmount),
            currencyId: order.currencyId,
            products: order.products,
            evenIfNotAvailable: false,
            remarks: order.remarks,
            channelId: order.channelId
        };
        const apiResult = await api.SaveOrder(date);
        if (apiResult.hasValidationError) {
            const message = apiResult
                .validationErrorsList()
                ?.map(x => x.msg)
                ?.join('\n');
            swal.fire({
                icon: 'error',
                text: message
            });
            if (message) {
                onErrorMessages(message);
            }
            return null;
        }

        if (apiResult.data?.errorCode == 6) {
            const swalResult = await swal.fire({
                title: t('alert.areYouSure'),
                text: t('alert.createOrderWithLimiteQuantity'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesContinue'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return null;
            date.evenIfNotAvailable = true;
            const apiResultEvenIfNotAvailable = await api.SaveOrder(date);
            if (apiResultEvenIfNotAvailable.hasValidationError) {
                const message = apiResultEvenIfNotAvailable
                    .validationErrorsList()
                    ?.map(x => `Field '${x.key}': ${x.msg}`)
                    ?.join('\n');
                swal.fire({
                    icon: 'error',
                    text: message
                });
                return null;
            }
            if (apiResultEvenIfNotAvailable.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResultEvenIfNotAvailable.errorMessage
                });
                return null;
            }

            return apiResultEvenIfNotAvailable.data;
        }
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.errorMessage
            });
            if (apiResult.errorMessage) {
                onErrorMessages(apiResult.errorMessage);
            }
            return null;
        }

        return apiResult.data;
    }

    async function apiCalculateOrder(order: OrderDetails) {
        if (!order.products || !order.products.length) {
            order.priceTotal = 0;
            order.priceWithDiscount = 0;
            order.discountAmount = 0;
            order.discountPercent = 0;
            return null;
        }
        const priceTypeId = order.priceTypeId;
        const currency = order.currencyId;
        const products = order.products;

        const apiResult = await api.CalculateOrder(order.channelId, priceTypeId, 0, 0, currency, products);
        if (apiResult.data?.error) {
            swal.fire({
                icon: 'error',
                text: apiResult.data?.error
            });
            return null;
        }
        return apiResult.data;
    }

    return {
        saveStoreOrder,
        apiCalculateOrder,
        apiSaveOrder,
        AddProductInOrder,
        AddProductInExistOrder,
        removemillis,
        getEndTime,
        getMinutes,
        canLeave,
        convertDate,
        dateToJSON,
        dateToddmmyyyy
    };
}
